import React from "react";
import PropertyList from "./PropertyList";
import { useQueryParams } from "hookrouter";
import SearchTabProp from "../../../search/SearchTabProp";
import { SEARCH_PROPERTIES_QUERY } from "../../../query/queries/property";
import Query from "../../../query/Query";

export default function Allproperties() {
  let [
    {
      search = "",
      category = "",
      type = "",
      location_id,
      beds,
      minprice,
      maxprice,
    },
  ] = useQueryParams();
  return (
    <div className="flex w-full bg-gray-100 px-1 ">
      <div className="absolute w-full h-20 object-cover bg-white top-0 left-0 -z-10"></div>
      <div className="flex flex-wrap w-full min-h-screen">
        <div className="flex w-full flex-wrap ">
          <div className="flex-1  lg:border-r w-full lg:w-4/5 ">
            <div className="flex flex-wrap w-full max-w-4xl  mt-2 mx-auto">
              <div className="flex w-full mx-3 mb-4 border-b border-red-300">
                <div className="flex border shadow-lg w-full max-w-3xl mb-4 mx-auto mt-6">
                  <SearchTabProp
                    props={{ search, category, type, beds, minprice, maxprice }}
                  />
                </div>
              </div>
              {search !== "" && (
                <div className="flex w-full max-w-6xl mx-auto">
                  <span className="px-1 py-2 text-lg text-gray-700">
                    Result For "
                    <span className="text-orange-500">{search}</span>"
                  </span>
                </div>
              )}
              <div className="w-full h-full flex">
                <Query
                  query={SEARCH_PROPERTIES_QUERY}
                  search={search}
                  type={type}
                  location_id={
                    isNaN(Number(location_id)) || location_id === ""
                      ? undefined
                      : Number(location_id)
                  }
                  category={category}
                  beds={
                    isNaN(Number(beds)) || beds === ""
                      ? undefined
                      : Number(beds)
                  }
                  minprice={
                    isNaN(Number(minprice)) ? undefined : Number(minprice)
                  }
                  maxprice={
                    isNaN(Number(maxprice)) ? undefined : Number(maxprice)
                  }
                  loader="LOADER"
                >
                  {({ data }) => {
                    return <PropertyList data={
                      {
                        properties: data.map((property) => ({
                          id: property.id,
                          ...property.attributes,
                        }))
                      }
                    } />;
                  }}
                </Query>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
