import React, { useState } from "react";
import Carousel from "nuka-carousel";
import Image from "../../../common/Image";
import Transition from "../../../common/Transition";
import Email from "../../../common/Email";
import noimage from "../../../assets/noimage.png";
import Query from "../../../query/Query";
import { PROPERTYDETAILS_QUERY } from "../../../query/queries/property";
export default function PropertyDetails({ id }) {
  const [email, setemail] = useState(false);

  return (
    <div>
      <div className="flex min-h-screen bg-white w-full">
        <Query query={PROPERTYDETAILS_QUERY} id={id} loader="LOADER">
          {({ data }) => {
            let amenities = data.attributes?.amenities?.split(",");
            let services = data.attributes?.services?.split(",");
            let features = data.attributes?.features?.split(",");
            return (
              <div className="flex-1  border-r w-full lg:w-4/5 ">
                <div className="flex flex-wrap w-full bg-white border-t max-w-6xl border-b-4 border-blue-500 shadow-2xl my-4 mx-auto  rounded-md">
                  <div className="w-full md:w-1/2 ">
                    {data?.attributes?.images?.data?.length > 0 ? (
                      <Carousel infinite={false}>
                        {data?.attributes?.images.data.map((img, i) => {
                          return <Image key={i} src={img.attributes.url}></Image>;
                        })}
                      </Carousel>
                    ) : (
                      <div className="flex flex-wrap w-full bg-gray-200">
                        <div className="flex w-32 h-32 sm:w-48 sm:h-48 mx-auto my-auto">
                          <Image className="opacity-50" src={noimage}></Image>
                        </div>
                        <div className="flex w-full">
                          <span className="flex text-gray-600 text-lg mx-auto">
                            Image not Available
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-wrap w-full md:w-1/2">
                    <div className="w-full px-2 sm:px-6 py-4">
                      <div className="font-bold text-xl mb-2 uppercase">
                        {data?.attributes?.title || ""}
                      </div>
                      <div className="text-grey-400  mb-2">
                        {data?.attributes?.place || "Place not given"}
                      </div>
                      <div className="w-full">
                        <span className="rounded-md  text-2xl font-semibold text-red-700   float-left mb-2 uppercase">
                          {data?.attributes?.price && (
                            <span className="font-normal text-xl"> QAR </span>
                          )}
                          {data?.attributes?.price || (
                            <span className="font-normal text-red-400  mt-0">
                              Price not given
                            </span>
                          )}
                          {data?.attributes?.price &&
                            data?.attributes?.category?.name === "FOR RENT" && (
                              <span className="font-normal  lowercase pl-2 text-gray-500">
                                per month
                              </span>
                            )}
                        </span>
                        <span className=" bg-gray-700 rounded-md px-3 py-1  font-semibold text-white mr-2 float-right mb-2 uppercase">
                          {data?.attributes?.category?.name || ""}
                        </span>
                      </div>

                      <div className="grid grid-cols-1 w-full col-gap-4 row-gap-3 sm:grid-cols-2 mt-4 ">
                        <div className="sm:col-span-1  px-2 py-2 shadow-sm bg-gray-100">
                          <span className=" leading-5 font-normal text-gray-700">
                            {" "}
                            Area:{" "}
                          </span>
                          <span className="mt-1  leading-5 text-gray-900 capitalize">
                            {data?.attributes?.area || (
                              <span className="text-gray-600">Not given</span>
                            )}
                          </span>
                        </div>
                        <div className="sm:col-span-1 px-2 py-2 shadow-sm bg-gray-100">
                          <span className=" leading-5 font-normal text-gray-700">
                            {" "}
                            Bedrooms:{" "}
                          </span>
                          <span className="mt-1  leading-5 text-gray-900 capitalize">
                            {data?.attributes?.no_of_beds || (
                              <span className="text-gray-600">Not given</span>
                            )}
                          </span>
                        </div>
                        <div className="sm:col-span-1 px-2 py-2 shadow-sm bg-gray-100">
                          <span className=" leading-5 font-normal text-gray-700">
                            {" "}
                            Bathrooms:{" "}
                          </span>
                          <span className="mt-1  leading-5 text-gray-900 capitalize">
                            {data?.attributes?.no_of_bathrooms || (
                              <span className="text-gray-600">Not given</span>
                            )}
                          </span>
                        </div>
                        <div className="sm:col-span-1 px-2 py-2 shadow-sm bg-gray-100">
                          <span className=" leading-5 font-normal text-gray-700">
                            {" "}
                            Parking:{" "}
                          </span>
                          <span className="mt-1  leading-5 text-gray-900 capitalize">
                            {data?.attributes?.no_of_parking_space || (
                              <span className="text-gray-600">Not given</span>
                            )}
                          </span>
                        </div>
                        {/* <div className="sm:col-span-1 px-2 py-2 shadow-sm border  border-l-2 border-blue-300 rounded-sm">
                          <span className=" leading-5 font-normal text-gray-700">
                            {" "}
                            Ref No:{" "}
                          </span>
                          <span className="mt-1  leading-5 text-gray-900 capitalize">
                            {data?.attributes?.ref_no || (
                              <span className="text-gray-600">Not given</span>
                            )}
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-wrap mx-2 md:mx-4 w-full max-w-5xl">
                    <div className="flex flex-wrap bg-gray-100 mt-3 border w-full border-l-2 border-gray-300 px-2 py-2 rounded-sm">
                      <div className="text-grey-400  w-full text-md font-medium">
                        Description :
                      </div>
                      <p className="text-gray-700  mt-4 text-justify">
                        {data?.attributes?.description || ""}
                      </p>
                    </div>
                    <div className="w-full lg:w-1/2 mt-6    bg-gray-100 px-2 py-2 ">
                      <div className="text-blue-700 text-md mb-2 font-bold">
                        Features:
                      </div>
                      {features ? (
                        <ul className="list-disc">
                          {features.map((el, i) => {
                            return (
                              <li
                                key={i}
                                className="ml-6 mt-1  leading-5 text-gray-900 "
                              >
                                {el}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <span className="py-3 px-2 text-gray-700">
                          Not given
                        </span>
                      )}
                    </div>
                    <div className="flex flex-wrap mt-6 w-full lg:w-1/2 lg:pl-2">
                      <div className="w-full  bg-gray-100 px-2 py-2">
                        <div className="text-blue-700  w-full text-md mb-2 font-bold">
                          Amenities:
                        </div>
                        <div className="flex mt-1 w-full  leading-5 text-gray-900">
                          <div className="flex flex-wrap w-full rounded-md">
                            {amenities ? (
                              amenities.map((el, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="px-2 py-1 flex items-center justify-between  leading-5"
                                  >
                                    <div className=" flex-1 flex items-center">
                                      <div className="ml-2 flex  ">
                                        <div className="capitalize py-1 px-2 bg-blue-900 text-white rounded">
                                          {el}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <span className="py-2 px-2 text-gray-700">
                                Not given
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full mt-6 px-2 py-2 border border-l-2 border-gray-400 rounded-md">
                      <div className="text-blue-700 text-md mb-2 font-bold">
                        Services:
                      </div>
                      {services ? (
                        <ul className="list-disc">
                          {services.map((el, i) => {
                            return (
                              <li
                                key={i}
                                className="ml-6 mt-1  leading-5 text-gray-900 "
                              >
                                {el}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <span className="py-3 px-2 text-gray-700">
                          Not given
                        </span>
                      )}
                    </div>

                    <div className="w-full mb-4 grid grid-cols-1 col-gap-4 row-gap-2 sm:grid-cols-2 px-2 py-2 border border-l-2 border-teal-500 rounded-sm mt-6">
                      <div className="sm:col-span-1 ">
                        <div className="text-blue-700 text-md mb-2 font-bold">
                          Contact
                        </div>
                      </div>
                      <div className="sm:col-span-1 "></div>
                      <div className="sm:col-span-1 ">
                        <span className=" my-auto leading-5 font-normal text-gray-700">
                          Name :{" "}
                        </span>
                        <span className="mt-1  leading-5 text-gray-900 capitalize">
                          {data?.attributes?.holder_name || (
                            <span className="text-gray-600">Not given</span>
                          )}
                        </span>
                      </div>
                      <div className="sm:col-span-1">
                        <span className=" leading-5 font-normal text-gray-700">
                          {" "}
                          Ref No :{" "}
                        </span>
                        <span className="mt-1  leading-5 font-medium text-gray-900 capitalize">
                          {data?.attributes?.ref_no || (
                            <span className="text-gray-600 font-normal">
                              Not given
                            </span>
                          )}
                        </span>
                      </div>
                      <Transition
                        show={email}
                        enter="duration-500 linear"
                        enterFrom="opacity-0 -mt-16 scale-50"
                        enterTo="opacity-100 mt-0 scale-100"
                        leave="duration-200 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-0 -mt-64"
                      >
                        <Email
                          setemail={() => setemail(false)}
                          refno={data?.attributes?.ref_no}
                        />
                      </Transition>
                      <div className="sm:col-span-1">
                        <span className=" leading-5 font-normal text-gray-700">
                          {" "}
                          Contact No :{" "}
                        </span>
                        <span className="mt-1  leading-5 text-gray-900 capitalize">
                          {data?.attributes?.contact_no || (
                            <span className="text-gray-600">Not given</span>
                          )}
                        </span>
                      </div>
                      <div
                        className="sm:col-span-1"
                        onClick={() => {
                          setemail(true);
                        }}
                      >
                        <div className=" flex text-white ">
                          <span className="flex bg-blue-800 hover:bg-blue-700 rounded-lg  cursor-pointer px-2 sm:px-4 py-1 sm:py-2">
                            Email Us&nbsp;&nbsp;&nbsp;
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="18"
                              height="18"
                            >
                              <path
                                className="heroicon-ui"
                                d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="w-full mt- mb-3">
                      <div className="w-full flex ">
                        <PdfDoc
                          props={data.attributes}
                          amenities={amenities}
                          features={features}
                          services={services}
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    </div>
  );
}
