import { A } from "hookrouter";
import React, { useState } from "react";
import image1 from "../assets/homeSlider/image1.jpg";
import image2 from "../assets/cars/bmw.jpg";
import image3 from "../assets/hospitality/header.jpg";
export default function Modal({ show, setShow }) {
  const services = [
    {
      title: "Properties",
      details: "Premium real estate portal",
      link: "/property",
      img: image1,
    },
    {
      title: "Car Rental",
      details: "Premium real estate portal",
      link: "/car-rental",
      img: image2,
    },
    {
      title: "Hospitality",
      details: "Premium real estate portal",
      link: "/hospitality",
      img: image3,
    },
  ];
  return show ? (
    <div className="hidden md:flex items-center justify-center z-40 fixed h-full  transition duration-700  top-0 w-full ">
      <div className=" my-auto rounded-lg w-full p-4  bg-black bg-opacity-50 max-w-5xl mx-auto">
        <div className="flex flex-col items-start">
          <div className="flex items-center w-full">
            <svg
              onClick={setShow}
              className="ml-auto fill-current text-white w-6 h-6 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
          </div>
          <hr />
          <div className=" w-full p-4">
            <div className="grid grid-cols-1 gap-2 md:gap-4 sm:grid-cols-2 lg:grid-cols-3  w-full justify-center">
              {services.map((el, i) => (
                <A
                  key={i}
                  href={el.link}
                  className=" text-center cursor-pointer"
                >
                  <div className="py-16 relative mx-auto max-w-sm rounded overflow-hidden shadow-lg transition duration-500  bg-white h-64">
                    <div className="absolute top-0 w-full h-full">
                      <div className="absolute hover:-mt-32 transform transition-all ease-in-out duration-700 hover:bg-blue-900 text-white hover:bg-opacity-100 flex flex-wrap top-0  w-full min-h-full">
                        <div className="flex w-full h-48">
                          <span className="my-auto"></span>
                        </div>
                        <div className="flex w-full h-56 pb-20">
                          <div className="mx-auto py-4">
                            <div className="space-y-5">
                              <div className="font-bold text-xl mb-2">
                                {el.title}
                              </div>
                              <p className="text-base">{el.details}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img
                        src={el.img}
                        className="w-full object-cover h-full"
                      ></img>
                    </div>
                  </div>
                </A>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
