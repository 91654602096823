import React, { useState } from "react";

export default function Email({ setemail, refno }) {
  const [form, setform] = useState({
    name: "",
    email: "",
    country: "",
    phone: "",
    description: "",
    referece_no: refno,
  });
  const [error, seterror] = useState({
    name: false,
    email: false,
    country: false,
    phone: false,
    description: false,
  });
  onchange = (e) => {
    setform({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const validate = () => {
    let temperror = {
      name: false,
      email: false,
      country: false,
      phone: false,
      description: false,
    };
    let flag = true;
    if (form.name === "") {
      flag = false;
      temperror = { ...temperror, name: true };
    }
    if (form.email === "") {
      flag = false;
      temperror = { ...temperror, email: true };
    }
    if (form.country === "") {
      flag = false;
      temperror = { ...temperror, country: true };
    }
    if (form.phone === "") {
      flag = false;
      temperror = { ...temperror, phone: true };
    }
    if (form.description === "") {
      flag = false;
      temperror = { ...temperror, description: true };
    }
    seterror(temperror);
    return flag;
  };
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  const onsubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("Email sended successfully");
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...form }),
      })
        .then(() => alert("Success!"))
        .catch((error) => alert(error));

      setemail();
    }
  };
  return (
    <div className="fixed overflow-auto z-10 bottom-0 pt-14 inset-x-0 px-4 pb-4 inset-0 sm:flex sm:items-center sm:justify-center">
      <div onClick={setemail} className="fixed  inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <form
        data-netlify="true"
        method="POST"
        name="email_form"
        className="bg-white mt-16 rounded-lg overflow-auto shadow-xl transform transition-all sm:max-w-lg sm:w-full"
        role="dialog"
        onSubmit={onsubmit}
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="bg-white rounded px-8 pt-6 flex flex-col">
          <div className="-mx-3 md:flex flex-wrap  mb-2">
            <div className="md:w-1/2 px-3 mb-2">
              <label
                className="block uppercase tracking-wide text-grey-darker text-xs font-bold"
                htmlFor="grid-first-name"
              >
                Your Name
              </label>
              <input
                name="name"
                onChange={onchange}
                value={form.name}
                className="appearance-none text-sm block w-full bg-grey-lighter text-grey-darker border border-red rounded py-1 px-4 "
                type="text"
                placeholder="Name"
              />
              {error.name && (
                <p className="text-red-600 text-xs italic">
                  Please enter your name.
                </p>
              )}
            </div>
            <div className="md:w-1/2 px-3 mb-2 ">
              <label
                className="block uppercase tracking-wide text-grey-darker text-xs font-bold"
                htmlFor="grid-last-name"
              >
                Email
              </label>
              <input
                value={form.email}
                onChange={onchange}
                name="email"
                className="appearance-none block w-full text-sm bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-1 px-4"
                type="email"
                placeholder="example@gmail.com"
              />
              {error.email && (
                <p className="text-red-600 text-xs italic">
                  Please enter your email-id.
                </p>
              )}
            </div>
            <div className="md:w-1/2 px-3 mb-2">
              <label
                className="block uppercase tracking-wide text-grey-darker text-xs font-bold"
                htmlFor="grid-first-name"
              >
                Country
              </label>
              <input
                name="country"
                onChange={onchange}
                value={form.country}
                className="appearance-none block w-full text-sm bg-grey-lighter text-grey-darker border border-red rounded py-1 px-4 "
                type="text"
                placeholder="Country"
              />
              {error.country && (
                <p className="text-red-600 text-xs italic">
                  Please enter your country.
                </p>
              )}
            </div>
            <div className="md:w-1/2 px-3 mb-2">
              <label
                className="block uppercase tracking-wide text-grey-darker text-xs font-bold"
                htmlFor="grid-first-name"
              >
                Phone
              </label>
              <input
                name="phone"
                onChange={onchange}
                value={form.phone}
                className="appearance-none block w-full text-sm bg-grey-lighter text-grey-darker border border-red rounded py-1 px-4 "
                type="text"
                placeholder="Number with country code"
              />
              {error.phone && (
                <p className="text-red-600 text-xs italic">
                  Please enter your phone number.
                </p>
              )}
            </div>
            <div className="w-full px-3 mb-2">
              <label
                className="block uppercase tracking-wide text-grey-darker text-xs font-bold"
                htmlFor="grid-first-name"
              >
                Description
              </label>
              <textarea
                name="description"
                onChange={onchange}
                value={form.description}
                className="appearance-none block w-full text-sm bg-grey-lighter text-grey-darker border border-red rounded py-1 px-4 "
                rows="3"
                type="text"
                placeholder="Description"
              />
              {error.description && (
                <p className="text-red-600 text-xs italic">
                  Please fill out this field.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 sm:px-6 sm:flex sm:flex-row-reverse mb-2">
          <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <input
              type="submit"
              value="Send Email"
              className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-800 text-base leading-6 font-medium text-white shadow-sm hover:bg-teal-600 focus:outline-none focus:bg-teal-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            ></input>
          </span>
          <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              onClick={setemail}
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Cancel
            </button>
          </span>
        </div>
      </form>
    </div>
  );
}
