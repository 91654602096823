import React from "react";

export default function Image({ src = "", alt, className }) {
  const preurl =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:1337"
      : "";

  const imageUrl = src.includes("http") ? src : preurl.concat(src);
  return (
    <div className="relative pb-3/4 w-full ">
      <img
        src={imageUrl}
        alt={alt}
        className={className + " absolute object-cover w-full h-full"}
      ></img>
    </div>
  );
}
