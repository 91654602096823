import React from "react";
import { A } from "hookrouter";
import Header from "../../../common/HomeSlider";
import image1 from "../../../assets/homeSlider/image1.jpg";
import image2 from "../../../assets/homeSlider/image2.jpg";
import image3 from "../../../assets/homeSlider/image3.jpg";
import image4 from "../../../assets/homeSlider/image4.jpg";
import Query from "../../../query/Query";
import { FEATURED_PROPERTY_LIST_QUERY } from "../../../query/queries/property";
import SearchTabProp from "../../../search/SearchTabProp";
import Image from "../../../common/Image";
const slideContent = [
  {
    img: image1,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
  {
    img: image2,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
  {
    img: image3,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
  {
    img: image4,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
];

export default function PropertyHome() {
  return (
    <div className="w-full">
      <Header slideContent={slideContent} />
      <div className="w-full flex">
        <div className="w-full max-w-6xl mx-auto">
          <SearchTabProp />
        </div>
      </div>
      <div className="w-full mt-8 sm:mt-12 md:mt-20 flex">
        <span className="text-2xl font-semibold border-b-4 border-blue-900 mx-auto mb-8">
          FEATURED PROPERTIES
        </span>
      </div>
      <div className="flex w-full">
        <div className="flex w-full  max-w-6xl mx-auto justify-center flex-wrap">
          <Query query={FEATURED_PROPERTY_LIST_QUERY} loader="LOADER">
            {/* The first argument is an object with the parameter `data` which is an array of `properties` */}
            {({ data }) => {
              const properties = data.map((property) => ({
                id: property.id,
                ...property.attributes,
              }));
              return properties.map((item, i) => (
                <div className="flex mx-2">
                  <A
                    href={`/property/properties/${item.id}`}
                    className=" flex flex-wrap w-72 overflow-hidden mx-3 mb-4 rounded border-b-2 border-blue-800 transform transition duration-200 ease-in-out hover:shadow-xl hover:-translate-y-1"
                  >
                    <div className="absolute top-0 rounded-none scale-100 left-0 ">
                      <div className="flex">
                        <div className="flex my-auto ">
                          <span className="text-xs bg-orange-500 z-20 px-2 rounded-r-md text-white py-1">
                            FEATURED
                          </span>
                        </div>
                        <div
                          className="flex z-20 -ml-1"
                          style={{
                            height: 0,
                            width: 0,
                            borderRadius: 0,
                            borderLeft: "15px solid #ed8936",
                            borderRight: 0,
                            borderBottom: "15px solid transparent",
                            borderTop: "15px solid transparent",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="flex mt-3 w-full flex-wrap border bg-white shadow-lg ">
                      <div className="flex  w-full ">
                        <div className=" bg-cover bg-no-repeat w-full flex bg-center">
                          {/* If item.images.length === 0 => Use a placeholder, otherwise show the first image */}
                          {item?.images?.data?.length > 0 ? (
                            <Image
                              className="object-center"
                              alt="Property"
                              src={item?.images.data[0]?.attributes.url}
                            />
                          ) : (
                            <Image
                              className="object-center"
                              alt="Property"
                              src="https://via.placeholder.com/150"
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex w-full text-lg font-medium tracking-wider">
                        <span className="text-left px-3 py-2">
                          {item?.title}
                        </span>
                      </div>
                      {/* <div className="flex w-full text-gray-600 tracking-wider">
                        <p className="text-left px-3 py-2 ">
                          {item?.description}
                        </p>
                      </div> */}
                      <div className="flex w-full text-gray-900 tracking-wider">
                        <span className="px-3 py-1">{item?.place}</span>
                      </div>
                      <div className="flex w-1/2  tracking-wider">
                        <span className="px-3 py-1 text-2xl font-medium text-red-700">
                          QAR <span>{item?.price}</span>
                        </span>
                      </div>
                      <div className="flex ml-auto w-1/2 my-auto tracking-wider">
                        <span className="px-3  ml-auto bg-teal-600 rounded-lg text-white mr-2 py-1">
                          {item?.category?.name}
                        </span>
                      </div>
                    </div>
                  </A>
                </div>
              ));
            }}
          </Query>
        </div>
      </div>
      <div className="flex w-full">
        <div className="flex mx-auto mt-4 py-4">
          <A
            href="/property/allproperties"
            className="flex bg-blue-800  rounded-lg tracking-widest tansition duration-500 text-white hover:bg-blue-7000 focus:bg-blue-800 py-2 px-4 "
          >
            Explore More{" "}
            <div className="flex my-auto">
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
              >
                <path
                  className="heroicon-ui"
                  d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
                />
              </svg>
            </div>
          </A>
        </div>
      </div>
    </div>
  );
}
