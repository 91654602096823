import React, { useEffect, useState } from "react";
const Loader = () => {
  return (
    <div className="flex my-auto mx-auto ">
      <div
        style={{ borderTopColor: "#2a4365" }}
        className="w-12 h-12  animate-spin  rounded-full border-8 border-t-8 "
      ></div>
    </div>
  );
};
const FullLoader = () => {
  return (
    <div className="fixed  inset-0  z-40">
      <div className="absolute flex overflow-hidden h-screen inset-0 bg-white opacity-100">
        <Loader />
      </div>
    </div>
  );
};

const useFetch = (api, params) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Replace :id with params.id
        console.log("Replacing params");
        const paramaterizedApi = api.replace(/:(\w+)/g, (match, p1) => {
          console.log("Replacing " + match + " from ", params.variables);
          return params.variables[p1];
        });

        const API_BASE_URL = process.env.REACT_APP_BACKEND_URL

        const response = await fetch(
          API_BASE_URL + paramaterizedApi,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
        const json = await response.json();
        setData(json.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return { data, loading, error };
};


const Query = ({
  children,
  query,
  id,
  search,
  category,
  type,
  beds,
  transmission,
  seats,
  minprice,
  maxprice,
  loader = "",
}) => {
  const loadertype =
    loader === "FULL_LOADER" ? (
      <FullLoader />
    ) : loader === "LOADER" ? (
      <Loader />
    ) : (
      ""
    );
  const { data, loading, error } = useFetch(query, {
    variables: {
      id,
      search,
      category,
      type,
      transmission,
      seats,
      beds,
      minprice,
      maxprice,
    },
  });

  if (loading) return loadertype;
  if (error) {
    console.log(JSON.stringify(error));
    return loadertype;
  }
  return children({ data });
};

export default Query;
