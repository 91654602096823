import React from "react";
import { faSlidersH, faUser } from "@fortawesome/free-solid-svg-icons";
import testimage from "../../../assets/cars/bmw.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { A } from "hookrouter";
import Image from "../../../common/Image";

export default function CarCards({ data, featured }) {
  return (
    <div className="flex mx-auto">
      <A
        href={`/car-rental/${data.id}`}
        className="w-full flex flex-wrap max-w-sm sm:max-w-xs relative overflow-hidden mx-3 mb-4 rounded border-b-4 border-gray-400  duration-300 ease-in-out"
      >
        <div className="absolute top-0 rounded-none scale-100 left-0 ">
          {featured && (
            <div className="flex">
              <div className="flex my-auto ">
                <span className="text-xs bg-orange-500 px-2 z-20 rounded-r-md text-white py-1">
                  FEATURED
                </span>
              </div>
              <div
                className="flex z-20 h-0 w-0 -ml-1"
                style={{
                  borderRadius: 0,
                  borderLeft: "15px solid #ed8936",
                  borderRight: 0,
                  borderBottom: "15px solid transparent",
                  borderTop: "15px solid transparent",
                }}
              ></div>
            </div>
          )}
        </div>
        <div className="flex group mt-3 w-full flex-wrap border bg-white shadow-lg ">
          <div className="flex  w-full ">
            <div className=" w-full bg-cover bg-no-repeat relative overflow-hidden bg-center">
              <Image
                className="object-center group-hover:scale-105  duration-300 transform"
                alt="Image not available  "
                src={data.images.data[0]?.attributes.url}
              />
            </div>
          </div>
          <div className="flex w-2/3 text-lg font-medium tracking-wider">
            <span className="text-left px-3 py-2">{data.title}</span>
          </div>
          <div className="flex ml-auto w-1/3 my-auto tracking-wider">
            <span className="px-3  ml-auto bg-blue-900 text-sm rounded-lg text-white mr-2 py-1">
              {data.category?.name}
            </span>
          </div>

          <div className="flex w-full text-sm text-gray-900 tracking-wider">
            <div className="flex pl-3 mt-2 w-full">
              <div className="flex flex-wrap">
                <span className="text-gray-600 text-md">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <div className="w-full">{data.no_of_seats}</div>
              </div>
              <div className="flex ml-4 justify-center flex-col">
                <div className=" text-gray-600 pl-2 flex rotate-90 transform text-md">
                  <FontAwesomeIcon
                    className="mx-auto mt-auto mb-2"
                    icon={faSlidersH}
                  />
                </div>
                <div className="flex mx-auto">{data.transmission}</div>
              </div>
            </div>
          </div>
          <div className="flex w-full tracking-wider">
            <span className="px-3 py-1 text-2xl font-medium text-red-700">
              QAR {data.price}
            </span>
            <span className="text-gray-600 my-auto">per Day</span>
          </div>
        </div>
      </A>
    </div>
  );
}
