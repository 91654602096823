import React from "react";

import { A } from "hookrouter";
import Footer from "./common/Footer";
import NavBar from "./common/NavBar";
import Header from "./common/HomeSlider";
import QuickAccess from "./common/QuickAccess";
import image1 from "./assets/homeSlider/image1.jpg";
import image2 from "./assets/homeSlider/image2.jpg";
import image3 from "./assets/homeSlider/image3.jpg";
import image4 from "./assets/homeSlider/image4.jpg";
import cars from "./assets/cars/bmw.jpg";
import hospitality from "./assets/hospitality/header.jpg";
const slideContent = [
  {
    img: image1,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
  {
    img: image2,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
  {
    img: image3,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
  {
    img: image4,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
];

export default function Home() {
  return (
    <div>
      <NavBar />
      <div
        style={{ minHeight: "450px" }}
        className="absolute top-0 -z-10 w-full"
      ></div>
      <Header slideContent={slideContent} />
      <QuickAccess />

      <div className="w-full flex">
        <span className="text-2xl mt-8 font-semibold mx-auto">ABOUT US</span>
      </div>

      <div className=" bg-gray-50 max-w-6xl mx-auto overflow-hidden pt-8">
        <div className="relative  max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg
            className="absolute bottom-full right-full transform -translate-x-56 translate-y-56 sm:translate-y-24 lg:-translate-x-1/2 xl:translate-y-10"
            width="404"
            height="404"
            fill="none"
            viewBox="0 0 404 404"
          >
            <defs>
              <pattern
                id="svg-pattern-squares-1"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width="404" height="404" fill="url(#svg-pattern-squares-1)" />
          </svg>

          <div className="relative pb-20 lg:pb-0 ">
            <div className="max-w-3xl mx-auto text-lg tracking-widest text-center px-4 leading-loose text-gray-800">
              <p>
                The Al Arman Group is a premium real estate portal where users
                can find independent houses/villas, apartments/flats, and plots
                for rent in Qatar. We also specialize in hospitality cleaning, 
                car rental, and limousine services.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-8 sm:mt-12  flex">
        <span className="text-2xl font-semibold  mx-auto mb-8">
          OUR SERVICES
        </span>
      </div>
      <section className="bg-white">
        <div className="container max-w-6xl mx-auto px-6 py-8">
          <div className="md:flex items-center">
            <div className="md:w-1/2 max-w-xl mx-auto">
              <h2 className="text-gray-900 text-2xl font-bold">
                Property Dealings
              </h2>

              <p className="text-gray-800 lg:max-w-md mt-4 tracking-wider">
                Experience the luxurious residential, commercial and leisure
                properties across the region, including the UAE, Qatar
              </p>

              <div className="flex items-center  mt-6">
                <A
                  className=" bg-blue-700 hover:bg-blue-900 focus:bg-blue-900 rounded-full text-white py-2 px-4 "
                  href="/property"
                >
                  Go to Page <span className="text-xl">→</span>
                </A>
              </div>
            </div>

            <div className="mt-8 md:mt-0 md:w-1/2">
              <div className="flex items-center justify-center lg:justify-end">
                <div className="max-w-lg">
                  <img
                    className="w-full h-64 object-cover object-center rounded-md"
                    src={image1}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="flex max-w-6xl mx-auto px-6 py-8">
          <div className="flex  flex-wrap  flex-col-reverse md:flex-row items-center">
            <div className="mt-8 md:mt-0 md:w-1/2">
              <div className="flex items-center justify-center lg:justify-end">
                <div className="max-w-lg">
                  <img
                    className="w-full h-64 object-cover object-center rounded-md"
                    src={cars}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="md:w-1/2 max-w-xl mx-auto">
              <div className="md:ml-8">
                <h2 className="text-gray-900 text-2xl font-bold">Car Rental</h2>

                <p className="text-gray-800 lg:max-w-md mt-4 tracking-wider">
                  No matter what kind of rental vehicle you are looking for, our
                  extensive fleet will provide a fantastic solution. We offer
                  Instant Refund, Flexible Pricing.
                </p>

                <div className="flex items-center mt-6">
                  <A
                    className=" bg-blue-700 hover:bg-blue-900 focus:bg-blue-900 rounded-full text-white py-2 px-4 "
                    href="/car-rental"
                  >
                    Go to Page <span className="text-xl">→</span>
                  </A>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="container max-w-6xl mx-auto px-6 py-8">
          <div className="md:flex items-center">
            <div className="md:w-1/2 max-w-xl mx-auto">
              <h2 className="text-gray-900 text-2xl font-bold">
                {" "}
                Hopitality Cleaning
              </h2>

              <p className="text-gray-800 lg:max-w-md mt-4 tracking-wider">
                Great value for money on all Hospitality Cleaning contracts! Our
                experienced Hospitality Cleaners are ready to get the job done
                to perfection!
              </p>

              <div className="flex items-center mt-6">
                <A
                  className=" bg-blue-700 hover:bg-blue-900 focus:bg-blue-900 rounded-full text-white py-2 px-4 "
                  href="/hospitality"
                >
                  Go to Page <span className="text-xl">→</span>
                </A>
              </div>
            </div>

            <div className="mt-8 md:mt-0 md:w-1/2">
              <div className="flex items-center justify-center lg:justify-end">
                <div className="max-w-lg">
                  <img
                    className="w-full h-64 object-cover object-center rounded-md"
                    src={hospitality}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-indigo-200 bg-opacity-25">
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <h2 className="max-w-md mx-auto text-3xl leading-9 font-extrabold text-indigo-900 text-center lg:max-w-xl lg:text-left">
              The world's most innovative companies use Workflow
            </h2>
            <div className="flow-root self-center mt-8 lg:mt-0">
              <div className="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4">
                <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                  <img
                    className="h-12"
                    src="https://tailwindui.com/img/logos/workcation-logo-indigo-900.svg"
                    alt="Workcation"
                  />
                </div>
                <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                  <img
                    className="h-12"
                    src="https://tailwindui.com/img/logos/tuple-logo-indigo-900.svg"
                    alt="Tuple"
                  />
                </div>
                <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                  <img
                    className="h-12"
                    src="https://tailwindui.com/img/logos/level-logo-indigo-900.svg"
                    alt="Level"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
