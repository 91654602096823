import React from "react";
import { useRoutes } from "hookrouter";
import NavBar from "../../../common/NavBar";
import Footer from "../../../common/Footer";
import PropertyHome from "./Home";
import PropertyDetails from "./PropertyDetails";
import Allproperties from "./AllProperties";
export default function PropertyMain() {
  const routes = {
    "/": () => <PropertyHome />,
    "/allproperties": () => <Allproperties />,
    "/properties/:id": ({ id }) => <PropertyDetails id={id} />,
  };
  const navlink = [
    {
      title: "Home",
      link: "/property",
    },
    {
      title: "Properties",
      link: "/property/properties",
    },
  ];
  const routeResult = useRoutes(routes);
  return (
    <div className="">
      <NavBar navlink={navlink} />
      <div className="min-h-screen">{routeResult}</div>
      <Footer />
    </div>
  );
}
