import { useQueryParams } from "hookrouter";
import React from "react";
import { SEARCH_CARS_QUERY } from "../../../query/queries/cars";
import Query from "../../../query/Query";
import empty from "../../../assets/empty.png";
import SearchTabCar from "../../../search/SearchTabCar";
import CarCards from "./CarCards";

export default function AllCars() {
  let [
    {
      search = "",
      category = "",
      transmission = "",
      seats,
      minprice,
      maxprice,
    },
  ] = useQueryParams();
  return (
    <div className="flex w-full bg-gray-100 px-1 ">
      <div className="flex flex-wrap w-full min-h-screen">
        <div className="flex w-full flex-wrap ">
          <div className="flex-1  lg:border-r w-full  ">
            <div className="flex flex-wrap w-full max-w-6xl mt-2 mx-auto">
              <div className="flex w-full mx-3 mb-4 border-b border-blue-800 border-opacity-25">
                <div className="flex  w-full max-w-3xl mb-4 mx-auto mt-6">
                  <SearchTabCar
                    props={{
                      search,
                      category,
                      transmission,
                      seats,
                      minprice,
                      maxprice,
                    }}
                  />
                </div>
              </div>
              {search !== "" && (
                <div className="flex w-full max-w-6xl mx-auto">
                  <span className="px-1 py-2 text-lg text-gray-700">
                    Result For "
                    <span className="text-orange-500">{search}</span>"
                  </span>
                </div>
              )}
              <div className="flex w-full">
                <div className="flex w-full  max-w-6xl mx-auto flex-wrap">
                  <Query
                    query={SEARCH_CARS_QUERY}
                    search={search}
                    transmission={transmission}
                    category={category}
                    seats={
                      isNaN(Number(seats)) || seats === ""
                        ? undefined
                        : Number(seats)
                    }
                    minprice={
                      isNaN(Number(minprice)) ? undefined : Number(minprice)
                    }
                    maxprice={
                      isNaN(Number(maxprice)) ? undefined : Number(maxprice)
                    }
                    loader="LOADER"
                  >
                    {({ data: { cars } }) => {
                      console.log("dds", cars);
                      return cars?.length > 0 ? (
                        cars?.map((item, i) => <CarCards key={i} data={item} />)
                      ) : (
                        <div className="flex flex-wrap mx-auto my-auto align-middle">
                          <div className="flex w-full">
                            <span className="mx-auto text-center text-gray-500 text-lg font-mono tracking-widest">
                              No results match your search
                            </span>
                          </div>
                          <img
                            src={empty}
                            className="w-full max-w-md mx-auto"
                          />
                        </div>
                      );
                    }}
                  </Query>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   const array = [];
    //   return (
    //     <div className="w-full">
    //       <div className="flex w-full">
    //         <div className="flex w-full  max-w-6xl mx-auto flex-wrap">
    //           {array.map((el, i) => {
    //             return <CarCards key={i} data={el} featured={true} />;
    //           })}
    //         </div>
    //       </div>
    //     </div>
  );
}
