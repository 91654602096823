import React, { useState } from "react";
import quickSvg from "../assets/Icon-quickServices.svg";
import Modal from "./Modal";
export default function QuickAccess() {
  const [show, setShow] = useState(false);
  return (
    <div className="w-full">
      <div className="hidden md:flex fixed  bottom-0 left-0 z-30 ">
        <div className="flex dropdown relative">
          <img className="w-20 object-cover z-20" src={quickSvg} />
          <button
            className="bg-blue-900 text-white font-medium focus:outline-none  dropdown-content left-0  pl-12 pr-4 my-auto -ml-12 py-2 rounded-full"
            onClick={() => setShow(!show)}
          >
            Quick Access
          </button>
        </div>
      </div>
      <Modal show={show} setShow={() => setShow(!show)} />
    </div>
  );
}
