import React, { useEffect, useState } from "react";
import rrvideo from "../../../assets/cars/RRvideo.mp4";
export default function Video() {
  const [videoload, setvideoload] = useState(false);
  useEffect(() => {
    const videoref = document.getElementById("videos");

    videoref.addEventListener("canplaythrough", (event) => {
      setvideoload(true);
    });
  }, []);
  return (
    <div className="flex h-auto w-full">
      <div className="absolute bg-opacity-50 bg-black h-full flex z-10 w-full ">
        <div className="flex w-full max-w-4xl mx-3 sm:mx-auto ">
          <div className="mx-auto my-auto  text-white">
            <div className="text-3xl font-bold py-2 text-center">
              Our Fleet, Your Fleet
            </div>
            <div className="text-center text-base py-1">
              We know the difference is in the details and that’s why our car
              rental services, in the tourism and business industry, stand out
              for their quality and good taste, to offer you an unique
              experience
            </div>
            <div className="text-3xl text-center py-2">
              Call Now +974-704-006-51
            </div>
          </div>
        </div>
      </div>
      <div className="flex bg-black w-full">
        <video
          className={`${
            videoload ? "" : "hidden"
          } h-screen object-cover w-screen`}
          id="videos"
          src={rrvideo}
          autoPlay={true}
          loop={true}
          muted={true}
          preload="auto"
        ></video>
      </div>
    </div>
  );
}
