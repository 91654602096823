import React from "react";
import { A } from "hookrouter";
import noimage from "../../../assets/noimage.png";
import empty from "../../../assets/empty.png";
import Image from "../../../common/Image";
export default function PropertyList({ data }) {
  return (
    <div className="flex flex-wrap w-full mb-4">
      {data?.properties[0] ? (
        data.properties.map((el, index) => {
          return (
            <div
              key={index}
              className={`flex flex-wrap w-full sm:w-1/2 lg:w-full`}
            >
              <div
                className={`flex flex-wrap w-full hover:shadow-2xl m-2 lg:m-0 lg:border-b border shadow-sm bg-white ${index === 0 ? "border-t" : ""
                  }`}
              >
                {el?.images?.data?.length > 0 ? (
                  <div className="flex bg-gray-200 w-full my-auto lg:w-1/3 lg:h-64">
                    <A
                      href={`/property/properties/${el.id}`}
                      className="flex w-full"
                    >
                      <Image
                        className="object-center w-full"
                        src={el?.images.data[0]?.attributes.url}
                        alt="Image not available"
                      ></Image>
                    </A>
                  </div>
                ) : (
                  <div className="flex bg-gray-200 w-full my-auto lg:w-1/3 h-64">
                    <div className="flex border border-gray-400 flex-wrap w-full bg-gray-200">
                      <div className="flex w-32 h-32 sm:w-48 sm:h-48 mx-auto my-auto">
                        <img className="opacity-50" src={noimage} alt="Unavailable"></img>
                      </div>
                      <div className="flex w-full">
                        <span className="flex text-gray-600 text-lg mx-auto">
                          Image not Available
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex-1  w-full lg:w-2/3 ">
                  <div className="flex flex-wrap w-full">
                    <A
                      href={`/property/properties/${el.id}`}
                      className="flex flex-wrap w-full lg:w-2/3"
                    >
                      <div className="flex w-full">
                        <span className=" text-xl lg:text-2xl mt-2 pl-6 font-medium text-gray-900">
                          {el.title}
                        </span>
                      </div>
                      <div className="flex w-full">
                        <span className="text-sm sm:text-md lg:text-lg pl-6 font-normal text-gray-600">
                          {el.__typename}
                        </span>
                      </div>
                    </A>
                    {el.price ? (
                      <div className="flex flex-wrap w-full lg:w-1/3">
                        <div className="flex lg:w-full">
                          <span className="ml-auto lg:mr-6 text-lg sm:text-xl lg:text-2xl lg:mt-2 pl-6 font-medium text-red-600">
                            <span className="font-normal text-md sm:text-lg lg:text-xl">
                              QAR
                            </span>{" "}
                            {el.price}
                          </span>
                        </div>

                        <div className="flex lg:w-full">
                          {el.category.name === "FOR RENT" && (
                            <span className="ml-auto mr-4 pl-2 my-auto text-md lg:pl-6 font-normal text-gray-500">
                              per month
                            </span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-wrap w-full lg:w-1/3">
                        <div className="flex lg:w-full">
                          <span className="ml-auto lg:mr-6 text-lg sm:text-xl lg:text-2xl lg:mt-2 pl-6 font-medium text-red-500">
                            <span className="font-normal text-sm sm:text-md">
                              Price not available
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="flex pl-6 pt-2 lg:pt-4 w-full ">
                      <div className="tooltip my-auto">
                        <div className="flex px-2 mx-1 py-1 bg-gray-200 rounded-md">
                          <span className=" pr-2 my-auto">{el.no_of_beds}</span>
                          <div className="flex my-auto">
                            <svg
                              id="Capa_1"
                              height="20"
                              viewBox="0 0 512 512"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                id="XMLID_874_"
                                d="m487.005 256.669v-9.751c0-17.963-9.3-33.786-23.33-42.936v-106.368c0-27.986-22.768-50.754-50.754-50.754h-313.842c-27.986 0-50.755 22.768-50.755 50.754v106.367c-14.03 9.15-23.33 24.973-23.33 42.936v9.751c-14.366 3.783-24.994 16.883-24.994 32.421v114.096c0 17.95 14.182 32.647 31.929 33.482v13.503c0 8.268 6.703 14.971 14.971 14.971s14.971-6.703 14.971-14.971v-13.463h388.257v13.463c0 8.268 6.703 14.971 14.971 14.971s14.971-6.703 14.971-14.971v-13.503c17.748-.836 31.93-15.533 31.93-33.483v-114.095c0-15.539-10.628-28.638-24.995-32.42zm-408.738-159.055c0-11.476 9.336-20.812 20.812-20.812h313.842c11.476 0 20.812 9.336 20.812 20.812v98.067h-19.962v-26.388c0-18.47-15.026-33.497-33.497-33.497h-80.558c-18.47 0-33.497 15.027-33.497 33.497v26.388h-20.438v-26.388c0-18.47-15.027-33.497-33.497-33.497h-80.558c-18.471 0-33.497 15.027-33.497 33.497v26.388h-19.962zm305.561 71.678v22.834c0 1.96-1.595 3.554-3.554 3.554h-80.558c-1.96 0-3.554-1.595-3.554-3.554v-22.834c0-1.96 1.595-3.554 3.554-3.554h80.558c1.96 0 3.554 1.594 3.554 3.554zm-167.99 0v22.834c0 1.96-1.595 3.554-3.554 3.554h-80.558c-1.96 0-3.554-1.595-3.554-3.554v-22.834c0-1.96 1.595-3.554 3.554-3.554h80.558c1.959 0 3.554 1.594 3.554 3.554zm-160.901 77.625c0-11.742 9.553-21.294 21.295-21.294h359.536c11.742 0 21.295 9.553 21.295 21.294v8.648h-402.126zm427.12 156.267c0 1.974-1.606 3.58-3.581 3.58h-444.953c-1.974 0-3.581-1.606-3.581-3.58v-114.095c0-1.974 1.606-3.58 3.581-3.58h444.954c1.974 0 3.581 1.606 3.581 3.58v114.095z"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>

                      <div className="tooltip">
                        <div className="flex px-2 mx-1 py-1 bg-gray-200 rounded-md">
                          <span className=" pr-2 my-auto">
                            {el.no_of_bathrooms}
                          </span>
                          <div className="flex my-auto">
                            <svg
                              id="Icons"
                              height="25"
                              viewBox="0 0 74 74"
                              width="25"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="m59 66h-44a13.014 13.014 0 0 1 -13-13v-8a1 1 0 0 1 1-1h58v2h-57v7a11.012 11.012 0 0 0 11 11h44a11.012 11.012 0 0 0 11-11v-7h-5v-2h6a1 1 0 0 1 1 1v8a13.014 13.014 0 0 1 -13 13z" />
                              <path d="m59 72h-4a1 1 0 0 1 -.832-.445l-4-6a1 1 0 0 1 .832-1.555h8a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1zm-3.465-2h2.465v-4h-5.131z" />
                              <path d="m19 72h-4a1 1 0 0 1 -1-1v-6a1 1 0 0 1 1-1h8a1 1 0 0 1 .832 1.555l-4 6a1 1 0 0 1 -.832.445zm-3-2h2.465l2.667-4h-5.132z" />
                              <path d="m4 45h-2v-34a9 9 0 0 1 18 0v4h-2v-4a7 7 0 0 0 -14 0z" />
                              <path d="m29 26h-20a1 1 0 0 1 -1-1 11 11 0 0 1 22 0 1 1 0 0 1 -1 1zm-18.945-2h17.89a9 9 0 0 0 -17.89 0z" />
                              <path d="m26.836 40.012-.608-3.954 1.977-.3.608 3.954zm-1.216-7.907-.608-3.954 1.977-.3.611 3.949z" />
                              <path d="m11.164 40.012-1.977-.3.613-3.957 1.977.3zm1.216-7.907-1.98-.305.608-3.954 1.977.3z" />
                              <path d="m21.836 40.012-.608-3.954 1.977-.3.608 3.954zm-1.216-7.907-.608-3.954 1.977-.3.611 3.949z" />
                              <path d="m16.164 40.012-1.977-.3.608-3.954 1.977.3zm1.216-7.907-1.98-.305.608-3.954 1.977.3z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-2 lg:mt-6 pl-6 w-full ">
                      <span
                        className={`text-xs sm:text-sm uppercase text-white px-4 py-1 rounded-md  ${el.category?.name
                          ? el.category?.name?.toLowerCase().includes("sale")
                            ? "bg-green-600"
                            : "bg-orange-600"
                          : ""
                          }`}
                      >
                        {el.category?.name || ""}
                      </span>
                    </div>
                    <div className="flex mt-2  flex-wrap w-full ">
                      <div className="flex w-full">
                        <span className=" text-sm sm:text-md lg:text-md pl-6 font-normal text-gray-600">
                          {el.place}
                        </span>
                      </div>
                    </div>
                    <div className="flex pl-6  mt-1 mb-1 w-full ml-auto">
                      <div className="  mr-auto">
                        <div className="flex cursor-pointer rounded-full bg-gray-200">
                          <div className="flex-1 my-auto p-2  mx-auto">
                            <svg
                              height="20"
                              width="20"
                              viewBox="0 -28 512.001 512"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <A href={`/property/properties/${el.id}`}>
                        <div className="flex mr-4 lg:flex outline-none ml-auto py-2 ">
                          <div className=" cursor-pointer bg-gray-800 text-white px-4 py-1 border-0 hover:bg-gray-700">
                            View Details
                          </div>
                        </div>
                      </A>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="flex flex-wrap mx-auto my-auto align-middle">
          <div className="flex w-full">
            <span className="mx-auto text-center text-gray-500 text-lg font-mono tracking-widest">
              No results match your search
            </span>
          </div>
          <img src={empty} className="w-full max-w-md mx-auto" />
        </div>
      )}
    </div>
  );
}
