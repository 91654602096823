import React, { useState } from "react";
import { A } from "hookrouter";
import logo from "../assets/logo.png";
import Transition from "./Transition";

const NavBar = () => {
  const [showMenu, setshowMenu] = useState(false);
  return (
    <div className="z-10 w-full">
      <header className="shadow-sm bg-blue-900  text-gray-800 w-full">
        <nav className="flex items-center justify-between flex-wrap py-4 mx-auto px-2 z-20 sm:px-8 max-w-6xl">
          <div className="flex items-center flex-shrink-0 text-white mr-6">
            <A
              className="text-white no-underline hover:text-white hover:no-underline pl-1 sm:pl-2"
              href="/"
            >
              <img className="h-12" src={logo} />
            </A>
          </div>
          <div className="block lg:hidden">
            <button
              id="nav-toggle"
              onClick={() => {
                setshowMenu(!showMenu);
              }}
              className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-500 hover:border-gray-500 focus:outline-none"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden pt-6 lg:pt-0"
            id="nav-content"
          >
            <ul className="list-reset lg:flex justify-end flex-1 items-center">
              <li className="mr-3">
                <A
                  href="/property"
                  className="inline-block py-2 px-4 text-gray-400 font-medium active:text-white hover:text-white no-underline"
                >
                  <div className="mt-2 block mx-auto rounded-sm">PROPERTY</div>
                </A>
                <A
                  href="/car-rental"
                  className="inline-block py-2 px-4 text-gray-400 font-medium active:text-white hover:text-white no-underline"
                >
                  <div className="mt-2 block mx-auto rounded-sm">CARS</div>
                </A>
                <A
                  href="/hospitality"
                  className="inline-block py-2 px-4 text-gray-400 font-medium active:text-white hover:text-white no-underline"
                >
                  <div className="mt-2 block mx-auto rounded-sm">
                    HOSPITALITY
                  </div>
                </A>
                {/* <A
                  href="/aboutus"
                  className="inline-block py-2 px-4 text-gray-400 font-medium active:text-white hover:text-white no-underline"
                >
                  <div className="mt-2 block mx-auto rounded-sm">ABOUT US</div>
                </A>
                <A
                  href="/contactus"
                  className="inline-block py-2 px-4 text-gray-400 font-medium active:text-white hover:text-white no-underline"
                >
                  <div className="mt-2 block mx-auto rounded-sm">
                    CONTACT US
                  </div>
                </A> */}
              </li>
            </ul>
          </div>
        </nav>

        <Transition
          show={showMenu}
          enter="duration-700 ease-out"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-0 -mt-8"
        >
          <div className=" lg:hidden top-0 w-full bg-white bg-opacity-100 z-0  mt-2">
            <nav className="nav">
              <div className="flex text-gray-800 -z-10">
                <ul className="flex-1">
                  <A
                    href="/property"
                    className="mx-auto"
                    onClick={() => setshowMenu(false)}
                  >
                    <li className="flex w-full py-3 border-b border-white bg-blue-900 text-white hover:text-white px-4">
                      <span className="mx-auto">PROPERTY</span>
                    </li>
                  </A>
                  <A
                    href="/car-rental"
                    className="mx-auto"
                    onClick={() => setshowMenu(false)}
                  >
                    <li className="flex w-full py-3 border-b border-white bg-blue-900 text-white hover:text-white px-4">
                      <span className="mx-auto">CARS</span>
                    </li>
                  </A>
                  <A
                    href="/hospitality"
                    className="mx-auto"
                    onClick={() => setshowMenu(false)}
                  >
                    <li className="flex w-full py-3 border-b border-white bg-blue-900 text-white hover:text-white px-4">
                      <span className="mx-auto">HOSPITALITY</span>
                    </li>
                  </A>
                  {/* <A
                    href="/aboutus"
                    className="mx-auto"
                    onClick={() => setshowMenu(false)}
                  >
                    <li className="flex w-full py-3 border-b border-white bg-blue-900 text-white hover:text-white px-4">
                      <span className="mx-auto">ABOUT US</span>
                    </li>
                  </A>
                  <A
                    href="/contactus"
                    className="mx-auto"
                    onClick={() => setshowMenu(false)}
                  >
                    <li className="flex w-full py-3 border-b border-white bg-blue-900 text-white hover:text-white px-4">
                      <span className="mx-auto">CONTACT US</span>
                    </li>
                  </A> */}
                </ul>
              </div>
            </nav>
          </div>
        </Transition>
      </header>
    </div>
  );
};

export default NavBar;
