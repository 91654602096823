import React from "react";
import { useRoutes } from "hookrouter";
import NavBar from "../../../common/NavBar";
import Footer from "../../../common/Footer";
import HospitalityHome from "./Home";

export default function HospitalMain() {
  const routes = {
    "/": () => <HospitalityHome />,
  };
  const navlink = [
    {
      title: "Home",
      link: "/car-rental",
    },
    {
      title: "Properties",
      link: "/car-rental/properties",
    },
  ];
  const routeResult = useRoutes(routes);
  return (
    <div className="">
      <NavBar navlink={navlink} />
      <div className="min-h-screen">{routeResult}</div>
      <Footer />
    </div>
  );
}
