export const CATEGORY = [
  { option: "SELECT", value: "" },
  { option: "For Rent", value: "rent" },
  { option: "For Sale", value: "sale" },
];
export const TYPE = [
  { option: "TYPE", value: "" },
  { option: "Duplex", value: "Duplex" },
  { option: "Apartment", value: "Apartment" },
  { option: "Office Space", value: "Office Space" },
  { option: "Villa", value: "Villa" },
  { option: "Bulk Units", value: "Bulk Units" },
];
export const BEDS = [
  { option: "BEDROOMS", value: undefined },
  { option: "1 Bedrooms", value: 1 },
  { option: "2 Bedrooms", value: 2 },
  { option: "3 Bedrooms", value: 3 },
  { option: "4 Bedrooms", value: 4 },
  { option: "5 Bedrooms", value: 5 },
  { option: "6 Bedrooms", value: 6 },
  { option: "7+ Bedrooms", value: 7 },
];
export const MINPRICE = [
  { option: "QAR MIN PRICE", value: 0 },
  { option: "3,000", value: 3000 },
  { option: "5,000", value: 5000 },
  { option: "10,000", value: 10000 },
  { option: "30,000", value: 30000 },
  { option: "50,000", value: 50000 },
  { option: "100,000 +", value: 100000 },
];
export const MAXPRICE = [
  { option: "QAR MAX PRICE", value: undefined },
  { option: "3,000", value: 3000 },
  { option: "5,000", value: 5000 },
  { option: "10,000", value: 10000 },
  { option: "30,000", value: 30000 },
  { option: "50,000", value: 50000 },
  { option: "100,000 +", value: undefined },
];

// cars
export const CAR_CATEGORY = [
  { option: "SELECT", value: "" },
  { option: "For Rent", value: "rent" },
  { option: "For Sale", value: "sale" },
];
export const CAR_TRANSMISSION = [
  { option: "Transmission", value: "" },
  { option: "Auto", value: "Auto" },
  { option: "Mannual", value: "Mannual" },
];

export const CAR_MINPRICE = [
  { option: "QAR MIN PRICE", value: 0 },
  { option: "3,000", value: 3000 },
  { option: "5,000", value: 5000 },
  { option: "10,000", value: 10000 },
  { option: "30,000", value: 30000 },
  { option: "50,000", value: 50000 },
  { option: "100,000 +", value: 100000 },
];
export const CAR_MAXPRICE = [
  { option: "QAR MAX PRICE", value: undefined },
  { option: "3,000", value: 3000 },
  { option: "5,000", value: 5000 },
  { option: "10,000", value: 10000 },
  { option: "30,000", value: 30000 },
  { option: "50,000", value: 50000 },
  { option: "100,000 +", value: undefined },
];
