import React from "react";
import headerimg from "../../../assets/hospitality/header.jpg";
import image1 from "../../../assets/hospitality/header.jpg";
import Header from "../../../common/HomeSlider";

const slideContent = [
  {
    img: image1,
    content:
      "We provide cleaning services specially for hospitals. We can provide quality cleaning services for you.",
  },
  {
    img: image1,
    content:
      "We provide cleaning services specially for hospitals. We can provide quality cleaning services for you.",
  },
  {
    img: image1,
    content:
      "We provide cleaning services specially for hospitals. We can provide quality cleaning services for you.",
  },
  {
    img: image1,
    content:
      "We provide cleaning services specially for hospitals. We can provide quality cleaning services for you.",
  },
];
export default function HospitalityHome() {
  return (
    <div className="w-full">
      <Header slideContent={slideContent} />
      <div className="w-full mt-8 sm:mt-12 md:mt-20 flex">
        <span className="text-2xl font-semibold border-b-4 border-teal-600 mx-auto mb-8">
          Our Services
        </span>
      </div>
      <div className="flex flex-wrap w-full mb-12 mx-auto max-w-5xl">
        <div className="flex w-full my-4 max-w-sm sm:max-w-xs border-teal-600 border-2 shadow-lg hover:shadow-2xl rounded-md mx-auto">
          <div className="flex mx-auto bg-white py-4 ">
            <span className="mx-auto py-4 px-6">GENERAL CLEANING SERVICES</span>
          </div>
        </div>
        <div className="flex w-full my-4 max-w-sm sm:max-w-xs border-blue-800 border-2 shadow-lg hover:shadow-2xl rounded-md mx-auto">
          <div className="flex mx-auto bg-white py-4 ">
            <span className="mx-auto py-4 px-6">CONSTRUCTION CLEANING</span>
          </div>
        </div>
        <div className="flex w-full my-4 max-w-sm sm:max-w-xs border-teal-600 border-2 shadow-lg hover:shadow-2xl rounded-md mx-auto">
          <div className="flex mx-auto bg-white py-4 ">
            <span className="mx-auto py-4 px-6">MOVE IN/ OUT CLEANING</span>
          </div>
        </div>
        <div className="flex w-full my-4 max-w-sm sm:max-w-xs border-blue-800 border-2 shadow-lg hover:shadow-2xl rounded-md mx-auto">
          <div className="flex mx-auto bg-white py-4 ">
            <span className="mx-auto py-4 px-6">DEEP CLEANING</span>
          </div>
        </div>
        <div className="flex w-full my-4 max-w-sm sm:max-w-xs border-red-600 border-2 shadow-lg hover:shadow-2xl rounded-md mx-auto">
          <div className="flex mx-auto bg-white py-4 ">
            <span className="mx-auto py-4 px-6">VACAD CLEANING</span>
          </div>
        </div>
        <div className="flex w-full my-4 max-w-sm sm:max-w-xs border-blue-800 border-2 shadow-lg hover:shadow-2xl rounded-md mx-auto">
          <div className="flex mx-auto bg-white py-4 ">
            <span className="mx-auto py-4 px-6">MARBLE CRYSTALLIZATION</span>
          </div>
        </div>
      </div>
    </div>
  );
}
