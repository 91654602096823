import gql from "graphql-tag";

export const PROPERTYDETAILS_QUERY = "/properties/:id?populate=*"

export const PROPERTYDETAILS_QUERY_GQL = gql`
  query Properties($id: ID!) {
    property(id: $id) {
      id
      title
      description
      price
      place
      area
      no_of_beds
      no_of_bathrooms
      no_of_parking_space
      ref_no
      services
      features
      amenities
      holder_name
      contact_no
      contact_email
      images {
        url
      }
      category {
        id
        name
      }
    }
  }
`;

export const FEATURED_PROPERTY_LIST_QUERY = "/properties?featured=true&populate=*";

export const FETAURED_PROPERTY_LIST_QUERY_GQL = gql`
  query Properties {
    properties(where: { featured: "true", published: "true" }) {
      id
      title
      price
      category {
        id
        name
      }
      images {
        url
      }
    }
  }
`;
export const SEARCH_PROPERTIES_QUERY = "/properties?populate=*";
export const SEARCH_PROPERTIES_QUERY_GQL = gql`
  query Properties(
    $search: String!
    $minprice: Int
    $maxprice: Int
    $type: String
    $category: String
    $beds: Int
  ) {
    properties(
      where: {
        published: "true"
        title_contains: $search
        price_gte: $minprice
        price_lte: $maxprice
        type_contains: $type
        no_of_beds: $beds
        category: { name_contains: $category }
      }
    ) {
      id
      title
      description
      price
      no_of_beds
      no_of_bathrooms
      place
      images {
        url
      }
      category {
        id
        name
      }
    }
  }
`;
