import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Slide = ({ content }) => {
  return (
    <div className=" relative  overflow-hidden w-full">
      <div className="absolute -z-10  top-0 bottom-0 w-full">
        <img src={content.img} className="w-full object-cover h-full" />
      </div>
      <div className="flex mt-10 sm:mt-40 mb-10 sm:mb-20 p-10 rounded-lg bg-black bg-opacity-50  flex-wrap mx-auto max-w-xl text-center md:text-left">
        <h1 className="w-full font-bold mb-8 text-3xl sm:text-5xl text-white ">
          AL ARMAN GROUP
        </h1>
        <span className="sm:mt-4 tracking-widest px-2 text-sm sm:text-base md:text-lg text-white">
          {content.content}
        </span>
      </div>
    </div>
  );
};
export default function Header({ slideContent }) {
  const [index, setIndex] = useState(0);
  return (
    <div className="flex-1 relative w-full bg-auto bg-repeat-x">
      <div className="flex w-full ">
        <div className="w-full">
          <AutoPlaySwipeableViews
            index={index}
            onChangeIndex={(index) => setIndex(index)}
            interval={5000}
          >
            {slideContent.map((el, i) => (
              <Slide key={i} content={el} />
            ))}
          </AutoPlaySwipeableViews>
        </div>
      </div>
      <div className="flex absolute bottom-0 z-10 w-full h-10 md:h-20">
        <svg
          className="w-1/3 h-full"
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          viewBox="0 0 100 10"
          preserveAspectRatio="none"
        >
          <polygon points="0,0 0,100 100,10 100,10 100,10"></polygon>
        </svg>
        <svg
          className="w-2/3 h-full"
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          viewBox="0 0 100 10"
          preserveAspectRatio="none"
        >
          <polygon points="100 0 100 10 0 10"></polygon>
        </svg>
      </div>
    </div>
  );
}
