import React, { useState } from "react";
import { A, navigate, useQueryParams } from "hookrouter";
import {
  CAR_CATEGORY,
  CAR_TRANSMISSION,
  CAR_MINPRICE,
  CAR_MAXPRICE,
} from "../common/filteroptions";
// import Query from "../Query";
// import { LOCATION_QUERY } from "../../queries/locations/locationlist";

export default function SearchTabCar({ props }) {
  console.log(props);
  const [search, setsearch] = useState(props?.search ? props.search : "");
  const [queryParams, setQueryParams] = useQueryParams();
  const initialstate = {
    category: props?.category ? props.category : "",
    transmission: props?.transmission ? props.transmission : "",
    location_id: props?.location_id ? props.location_id : undefined,
    beds: props?.beds ? props.beds : undefined,
    minprice: props?.minprice ? props.minprice : 0,
    maxprice: props?.maxprice ? props.maxprice : undefined,
  };
  const [form, setform] = useState(initialstate);
  const onchange = (e) => {
    setform({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(form);
    const tempform = { ...form, search: search };
    setQueryParams(tempform);
    navigate(`/car-rental/allcars`);
  };
  return (
    <div className="flex flex-wrap w-full">
      <form
        className="flex flex-wrap w-4/5 sm:max-w-none border-b-4 rounded-b-md rounded-t-sm border-blue-900 border-opacity-50 shadow-lg mx-auto max-w-sm  px-1 bg-white  hover:border-2"
        onSubmit={onSubmit}
      >
        <div className="px-1 my-auto mt-1 text-xs sm:mt-4 mx-auto flex w-full sm:w-1/3 ">
          <select
            value={form.category}
            onChange={onchange}
            name="category"
            className="w-full px-2 py-2 outline-none"
          >
            {CAR_CATEGORY.map((el, i) => {
              return (
                <option key={i} value={el.value}>
                  {el.option}
                </option>
              );
            })}
          </select>
        </div>

        <div className="flex mt-1 sm:mt-4 mx-auto w-full sm:w-1/3">
          <input
            className="w-full h-8 border px-2 text-md border-gray-400 mx-1 my-auto outline-none shadow-sm focus:shadow-lg"
            type="search"
            placeholder="Search..."
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
            }}
          />
        </div>

        <div className="px-1 my-auto mt-1 text-xs sm:mt-4 mx-auto flex w-full sm:w-1/3 ">
          <select
            value={form.transmission}
            onChange={onchange}
            name="transmission"
            className="w-full px-2 py-2 outline-none"
          >
            {CAR_TRANSMISSION.map((el, i) => {
              return (
                <option key={i} value={el.value}>
                  {el.option}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex flex-wrap w-full sm:pb-4">
          <div className="px-1 sm:pt-4 pt-1 pb-1 text-xs mx-auto flex w-full sm:w-1/3 ">
            <select
              value={form.minprice}
              onChange={onchange}
              name="minprice"
              className="w-full px-2 py-2 outline-none"
            >
              {CAR_MINPRICE.map((el, i) => {
                return (
                  <option key={i} value={el.value}>
                    {el.option}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="px-1 sm:pt-4 pt-1 pb-1 text-xs mx-auto flex w-full sm:w-1/3 ">
            <select
              value={form.maxprice}
              onChange={onchange}
              name="maxprice"
              className="w-full px-2 py-2 outline-none"
            >
              {CAR_MAXPRICE.map((el, i) => {
                return (
                  <option key={i} value={el.value}>
                    {el.option}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="flex mx-auto  mt-1 sm:mt-4 mb-2 my-auto  w-full sm:w-1/3 h-8  ">
            <div className="flex w-full mr-1">
              <input
                type="submit"
                value="Search"
                className="flex outline-none px-4 sm:px-12 bg-blue-700 hover:bg-blue-900 text-white mx-auto"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
