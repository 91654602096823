import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image1 from "../../../assets/cars/header.jpg";
import {
  faCar,
  faSmile,
  faHeart,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import { A } from "hookrouter";
import Video from "./Video";
import Header from "../../../common/HomeSlider";
import CarCards from "./CarCards";
import SearchTabCar from "../../../search/SearchTabCar";
import Query from "../../../query/Query";
import { FEATURED_CAR_LIST_QUERY } from "../../../query/queries/cars";

const slideContent = [
  {
    img: image1,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
  {
    img: image1,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
  {
    img: image1,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
  {
    img: image1,
    content:
      " Looking to Buy a new property or sell an existing one? Al Arman provides an easy solution!",
  },
];

export default function CarHome() {
  return (
    <div className="w-full">
      <Header slideContent={slideContent} />
      <div className="w-full flex">
        <div className="w-full max-w-6xl mx-auto">
          <SearchTabCar />
        </div>
      </div>
      <div className="w-full mt-8 sm:mt-12 md:mt-20 flex">
        <span className="text-2xl font-semibold border-b-4 border-teal-600 mx-auto mb-8">
          FEATURED CARS
        </span>
      </div>
      <div className="flex w-full">
        <div className="flex w-full  max-w-6xl mx-auto flex-wrap">
          <Query query={FEATURED_CAR_LIST_QUERY} loader="LOADER">
            {({ data }) => {
              return data?.length > 0 ? (
                data?.map((el, i) => {
                  const carObject = {
                    id: el.id,
                    ...el.attributes,
                  };
                  return <CarCards key={i} data={carObject} featured={true} />;
                })
              ) : (
                // No Listing Found
                <div className="flex w-full">
                  <div className="flex mx-auto mt-4 py-4">
                    <span className="text-md text-gray-600">
                      No Listing Found
                    </span>
                  </div>
                </div>
              );
            }}
          </Query>
        </div>
      </div>
      <div className="flex w-full">
        <div className="flex mx-auto mt-4 py-4">
          <A
            href="/car-rental/allcars"
            className="flex bg-blue-800  rounded-lg tracking-widest tansition duration-500 text-white hover:bg-blue-7000 focus:bg-blue-800 text-sm py-2 px-4 "
          >
            Explore More{" "}
            <div className="flex my-auto">
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
              >
                <path
                  className="heroicon-ui"
                  d="M18.59 13H3a1 1 0 0 1 0-2h15.59l-5.3-5.3a1 1 0 1 1 1.42-1.4l7 7a1 1 0 0 1 0 1.4l-7 7a1 1 0 0 1-1.42-1.4l5.3-5.3z"
                />
              </svg>
            </div>
          </A>
        </div>
      </div>
      <div className=" w-full">
        <Video />
      </div>
      <div className="py-12 bg-white">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Why Choose Us
            </h3>
          </div>

          <div className="mt-10">
            <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center text-3xl justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      <FontAwesomeIcon icon={faCar} />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Variety of Car Brands
                    </h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Al Arman rental car in Qatar has a great rate and offers a
                      variety of pick-up and drop-off options to make your life
                      easier
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center text-3xl justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      <FontAwesomeIcon icon={faSmile} />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Best Rate Guarantee
                    </h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      We offer car hire in Qatar from compact economy cars with
                      low consumption to off road solutions, depending on your
                      preference
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center text-3xl justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      <FontAwesomeIcon icon={faHeart} />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Awesome Customer Support
                    </h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Our goal is to offer you a variety of car hire & chauffer
                      service at the very best price to suit our customer`s
                      needs and preferences
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center text-3xl justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                      <FontAwesomeIcon icon={faHandshake} />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">
                      Best Dealings
                    </h4>
                    <p className="mt-2 text-base leading-6 text-gray-500">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Maiores impedit perferendis suscipit eaque, iste dolor
                      cupiditate blanditiis ratione.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
