import gql from "graphql-tag";

export const CAR_DETAILS_QUERY = "/cars/:id?populate=*";

export const CAR_DETAILS_QUERY_GQL = gql`
  query Cars($id: ID!) {
    cars(where: { id: $id }) {
      title
      description
      price
      no_of_seats
      ref_no
      features
      holder_name
      contact_no
      transmission
      contact_email
      images {
        url
      }
      category {
        id
        name
      }
    }
  }
`;

export const FEATURED_CAR_LIST_QUERY = "/cars?featured=true&populate=*";

export const FETAURED_CAR_LIST_QUERY_GQL = gql`
  query Cars {
    cars(where: { published: "true", featured: "true" }) {
      id
      title
      price
      no_of_seats
      transmission
      images {
        url
      }
      category {
        id
        name
      }
    }
  }
`;
export const SEARCH_CARS_QUERY = gql`
  query Cars(
    $search: String!
    $minprice: Int
    $maxprice: Int
    $transmission: String
    $category: String
    $seats: Int
  ) {
    cars(
      where: {
        published: "true"
        title_contains: $search
        price_gte: $minprice
        price_lte: $maxprice
        transmission_contains: $transmission
        no_of_seats: $seats
        category: { name_contains: $category }
      }
    ) {
      id
      title
      price
      no_of_seats
      transmission
      images {
        url
      }
      category {
        id
        name
      }
    }
  }
`;
