import React, { useEffect } from "react";
import { useRoutes } from "hookrouter";

import Home from "./Home";
import PropertyMain from "./apps/properties/components/PropertyMain";
import CarMain from "./apps/car-rental/components/CarMain";
import HospitalMain from "./apps/hospitality/components/HospitalMain";

const routes = {
  "/": () => <Home />,
  "/property*": () => <PropertyMain />,
  "/car-rental*": () => <CarMain />,
  "/hospitality*": () => <HospitalMain />,
};

function App() {
  const routeResult = useRoutes(routes);
  useEffect(() => window.scrollTo(0, 0));
  return (
    <div className=" flex w-full cursor-default flex-col">{routeResult}</div>
  );
}

export default App;
